import React from 'react';

const Registration = () => {
  return (
    <div className="text-center">
      <span>BotFleet is currently invite-only.</span>
    </div>
  );
};

export default Registration;
