import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TerminalModal = ({ content, show, onHide, loading }) => {
  return (
    <Modal show={show} onHide={onHide} fullscreen={true}>
      <Modal.Header className="d-flex justify-content-end">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <pre>Loading...</pre>
        ) : (
          <pre style={{ whiteSpace: 'pre-wrap' }}>{content}</pre>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TerminalModal;
