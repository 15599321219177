import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';

const CollapsibleCard = ({ title, isCollapsed, children }) => {
  const [cardIsCollapsed, setCardIsCollapsed] = useState(isCollapsed);
  const toggle = () => {
    setCardIsCollapsed(!cardIsCollapsed);
  };
  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0 fs-0">{title}</h5>
            <div className="d-flex align-items-end">
              <Button variant="falcon-primary" size="sm" onClick={toggle}>
                {cardIsCollapsed ? 'Show' : 'Hide'}
              </Button>
            </div>
          </div>
          {!cardIsCollapsed && <div className="mt-3">{children}</div>}
        </Card.Body>
      </Card>
    </>
  );
};

export default CollapsibleCard;
