import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import CodeEditor from 'components/common/code-editor/CodeEditor';

const CodeEditorInForm = ({
  name,
  control,
  rules,
  language,
  height,
  readOnly = false
}) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ name, control, rules });

  useEffect(() => {
    if (value) onChange(value);
  }, [value, onChange]);

  return (
    <>
      <div
        className={
          error ? 'border border-danger p-0' : 'border border-light p-0'
        }
      >
        <CodeEditor
          language={language}
          value={value}
          height={height}
          readOnly={readOnly}
          onChange={onChange}
        />
      </div>
      {error && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default CodeEditorInForm;
