import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Error404 from 'components/common/Error404';
import { deleteStore, getStore } from 'helpers/requests/stores';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Panel from 'components/stores/store-details/Panel';
import { Card } from 'react-bootstrap';

const Store = () => {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});
  const [notFound, setNotFound] = useState(false);
  const { id } = useParams();
  const isMounted = useRef(true);
  const navigate = useNavigate();

  let handleGetStore = async () => {
    const r = await getStore(id);
    if (r.success) {
      setStore(r.response.data);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setLoading(false);
  };

  const handleDeleteStore = async () => {
    if (window.confirm('Are you sure you want to delete this store?')) {
      const r = await deleteStore(id);
      if (r.success) {
        if (isMounted.current) {
          navigate(appPaths.stores.list);
        }
        toast.success('Store deleted successfully.');
      } else {
        if (r.response.status === 404) {
          toast.error('Store not found. Maybe it was already deleted?');
        } else if (r.response.status === 400) {
          // Store may be in use.
          toast.error(r.response.data.message);
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  useEffect(() => {
    handleGetStore();
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <>
      <Panel
        loading={loading}
        store={store}
        handleDeleteStore={handleDeleteStore}
      />
      {!loading && (
        <Card className="mb-3">
          <Card.Body>
            <pre>{store.data}</pre>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default Store;
