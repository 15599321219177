import React from 'react';
import { appPaths } from 'config';
import { Col } from 'react-bootstrap';
import InfoCard from 'components/stats/generic/InfoCard';

const StoresInfo = ({ count }) => {
  return (
    <Col key={'Stores'} sm={6}>
      <InfoCard
        title={'Stores'}
        count={count}
        viewLink={appPaths.stores.list}
        createLink={appPaths.stores.create}
      />
    </Col>
  );
};

export default StoresInfo;
