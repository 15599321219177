import React from 'react';
import { appPaths } from 'config';
import { Col } from 'react-bootstrap';
import InfoCard from 'components/stats/generic/InfoCard';

const BotsInfo = ({ count }) => {
  return (
    <Col key={'Bots'} sm={6}>
      <InfoCard
        title={'Bots'}
        count={count}
        viewLink={appPaths.bots.list}
        createLink={appPaths.bots.create}
      />
    </Col>
  );
};

export default BotsInfo;
