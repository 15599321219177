import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';

const InfoCard = ({ title, count, viewLink, createLink }) => {
  return (
    <Card className="overflow-hidden">
      <Background image={bg2} className="bg-card" />
      <Card.Body className="position-relative">
        <Row className="d-flex align-items-center">
          <Col sm={6}>
            <h6>{title}</h6>
            <div
              className={classNames(
                'text-info',
                'display-4 fs-4 mb-2 fw-normal font-sans-serif'
              )}
            >
              <CountUp
                start={0}
                end={count}
                duration={2.75}
                suffix={''}
                prefix={''}
                separator=","
                decimals={0}
                decimal="."
              />
            </div>
            <Link to={viewLink} className="fw-semi-bold fs--1 text-nowrap">
              View
              <FontAwesomeIcon
                icon="angle-right"
                className="ms-1"
                transform="down-1"
              />
            </Link>
          </Col>
          <Col sm={6} className="d-flex align-items-center justify-content-end">
            <Button
              as={Link}
              to={createLink}
              variant="outline-primary"
              size="sm"
            >
              <FontAwesomeIcon icon="plus" className="me-1" />
              Create
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoCard;
