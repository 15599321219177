import axios from 'axios';
import { settings, appPaths, apiPaths } from 'config';

const ax = axios.create({
  baseURL: settings.APIBaseURL
});

const getCsrfToken = async () => {
  const response = await axios.get(
    `${settings.APIBaseURL}${apiPaths.appUsers.auth.csrf.slice(1)}`,
    {
      withCredentials: true
    }
  );
  return response.data.csrf_token;
};

ax.interceptors.request.use(
  async config => {
    if (
      config.method === 'post' ||
      config.method === 'delete' ||
      config.method === 'put' ||
      config.method === 'patch'
    ) {
      config.headers = {
        ...config.headers,
        'X-CSRFToken': await getCsrfToken()
      };
    }
    config.withCredentials = true;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

ax.interceptors.response.use(
  response => response,
  error => {
    // Some errors like "connection refused" don't have a response object, which
    // leads to an uncaught error when status is destructured.
    // This behavior is good for us because these errors will be rare and
    // we want Sentry to identify them. Also, functions that depend on `ax`,
    // can be sure that since this interceptor didn't break (due to an
    // uncaught error), the response object will always be there.
    const {
      response: { status }
    } = error;
    if (status === 401) {
      window.location.href =
        appPaths.auth.login +
        '?next=' +
        window.location.pathname +
        window.location.search;
    }
    return Promise.reject(error);
  }
);

export { ax };
