import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { updateUser } from 'helpers/requests/users';
import { appPaths } from 'config';
import { toast } from 'react-toastify';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { setFormErrors } from 'helpers/errors';

const UpdateUserForm = ({ user }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const isMounted = useRef(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      full_name: user.full_name
    }
  });
  const navigate = useNavigate();

  const submitForm = async data => {
    setSubmittingForm(true);

    const r = await updateUser(data.full_name);

    if (r.success) {
      if (isMounted.current) {
        navigate(appPaths.settings.profile);
      }
      toast.success('User edited successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Card className="mb-3 bg-light">
        <Card.Body>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} md={6} sm={12}>
              <Form.Label>Full name</Form.Label>
              <Form.Control
                placeholder="Full name"
                name="full_name"
                type="text"
                isInvalid={!!errors.full_name}
                {...register('full_name', {
                  required: 'Enter full name.'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.full_name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      {errors.root?.nonFieldError?.message && (
        <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
      )}
      <Card>
        <Card.Body>
          <Form.Group className="d-flex justify-content-end">
            <Button type="submit" disabled={submittingForm}>
              Save
            </Button>
          </Form.Group>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateUserForm;
