import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'contexts/AppContext';
import React, { useContext } from 'react';
import { Nav, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'} className="me-2">
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <div className="d-flex">
        <Nav.Item as={'li'} className="me-2">
          <Button
            variant="primary"
            as={Link}
            to={appPaths.auth.register}
            size="sm"
            className="d-none d-sm-block"
          >
            Register
          </Button>
        </Nav.Item>
        <Nav.Item as={'li'}>
          <Button
            variant="outline-primary"
            as={Link}
            to={appPaths.auth.login}
            size="sm"
          >
            Login
          </Button>
        </Nav.Item>
      </div>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
