import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import { useLocation } from 'react-router-dom';
import Section from 'components/common/Section';
import { Outlet } from 'react-router-dom';
import queryString from 'query-string';
import { appPaths } from 'config';

const AuthLayout = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const nextQueryString = queryParams.next ? `?next=${queryParams.next}` : '';

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Logo to={`${appPaths.auth.login}${nextQueryString}`} />
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthLayout;
