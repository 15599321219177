import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { createBotExecutorCronJob } from 'helpers/requests/bots';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import GenerateCronJobExpression from 'components/bots/bot-details/cron-jobs/GenerateCronJobExpression';

const CreateCronJobForm = ({ botId, setNewCronJob, handleModalClose }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [useAI, setUseAI] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm();

  const toggleUseAI = () => {
    setUseAI(prevValue => !prevValue);
  };

  const submitForm = async data => {
    setSubmittingForm(true);

    const r = await createBotExecutorCronJob(data.name, data.expression, botId);
    if (r.success) {
      setNewCronJob(r.response.data);
      handleModalClose();
      toast.success('Schedule created');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  const setExpressionValue = value => {
    setValue('expression', value);
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} md={6} sm={12}>
            <Form.Label>Name*</Form.Label>
            <Form.Control
              placeholder="Name"
              name="name"
              type="text"
              isInvalid={!!errors.name}
              {...register('name', {
                required: 'Enter name'
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6} sm={12}>
            <Form.Label>Cron expression*</Form.Label>
            <Form.Control
              placeholder="Expression"
              name="expression"
              type="text"
              isInvalid={!!errors.expression}
              {...register('expression', {
                required: 'Enter expression'
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.expression?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="d-flex justify-content-between align-items-center">
            <Form.Check
              type="switch"
              label="Generate cron expression via AI"
              onChange={() => toggleUseAI()}
            />
            <Button type="submit" disabled={submittingForm}>
              Create
            </Button>
          </Form.Group>
        </Row>

        {errors.root?.nonFieldError?.message && (
          <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
        )}
      </Form>
      {useAI && (
        <GenerateCronJobExpression setExpressionValue={setExpressionValue} />
      )}
    </>
  );
};

export default CreateCronJobForm;
