import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function createBotTemplate(
  name,
  description,
  script,
  requirements,
  env_vars,
  python_version,
  is_public // public is a reserved keyword
) {
  const EXPECTED_STATUS_CODE = 201;

  try {
    const response = await ax.post(
      apiPaths.appBotTemplates.botTemplates.create,
      {
        name: name,
        ...(description !== undefined && { description: description }),
        script: script,
        ...(requirements !== undefined && { requirements: requirements }),
        ...(env_vars !== undefined && { env_vars: env_vars }),
        python_version: python_version,
        public: is_public
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBotTemplate(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appBotTemplates.botTemplates.detail(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBotTemplates() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBotTemplates.botTemplates.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteBotTemplate(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(
      apiPaths.appBotTemplates.botTemplates.delete(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function updateBotTemplate(id, payload) {
  const EXPECTED_STATUS_CODE = 204;
  const {
    name,
    description,
    script,
    requirements,
    env_vars,
    python_version,
    public: is_public // public is a reserved keyword
  } = payload;

  try {
    const response = await ax.patch(
      apiPaths.appBotTemplates.botTemplates.update(id),
      {
        ...(name !== undefined && { name: name }),
        ...(description !== undefined && { description: description }),
        ...(script !== undefined && { script: script }),
        ...(requirements !== undefined && { requirements: requirements }),
        ...(env_vars !== undefined && { env_vars: env_vars }),
        ...(python_version !== undefined && { python_version: python_version }),
        ...(is_public !== undefined && { public: is_public })
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
