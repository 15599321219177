import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function userIsAuthenticated() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appUsers.auth.status);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function logoutUser() {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(apiPaths.appUsers.auth.logout);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function loginUser(email, password) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(apiPaths.appUsers.auth.login, {
      email: email,
      password: password
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function registerUser(fullName, email, password) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(apiPaths.appUsers.auth.register, {
      full_name: fullName,
      email: email,
      password: password
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getUser() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appUsers.users.detail);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function updateUser(fullName) {
  const EXPECTED_STATUS_CODE = 204;

  try {
    const response = await ax.patch(apiPaths.appUsers.users.update, {
      full_name: fullName
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteUser() {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(apiPaths.appUsers.users.delete);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getUserPreferences() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appUsers.userPreferences.detail);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function updateUserPreferences(payload) {
  const { realTimeExecutions, codeEditorThemeDark, codeEditorThemeLight } =
    payload;

  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.patch(apiPaths.appUsers.userPreferences.update, {
      ...(realTimeExecutions !== undefined && {
        real_time_executions: realTimeExecutions
      }),
      ...(codeEditorThemeDark !== undefined && {
        code_editor_theme_dark: codeEditorThemeDark
      }),
      ...(codeEditorThemeLight !== undefined && {
        code_editor_theme_light: codeEditorThemeLight
      })
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function sendPasswordResetLink(email) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(
      apiPaths.appUsers.auth.sendPasswordResetLink,
      {
        email: email
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function resetPassword(userId, resetToken, password) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(apiPaths.appUsers.auth.resetPassword, {
      user_id: userId,
      reset_token: resetToken,
      password: password
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function sendEmailVerificationLink() {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(
      apiPaths.appUsers.auth.sendEmailVerificationLink,
      {}
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function verifyEmail(verificationToken) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(apiPaths.appUsers.auth.verifyEmail, {
      verification_token: verificationToken
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function createAPIKey() {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(apiPaths.appUsers.externalAPIKeys.create);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getAPIKeys() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appUsers.externalAPIKeys.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteAPIKey(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(
      apiPaths.appUsers.externalAPIKeys.delete(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
