import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { generateCronJobExpression } from 'helpers/requests/bots';

const GenerateCronJobExpression = ({ setExpressionValue }) => {
  const [generatingCronJobExpression, setGeneratingCronJobExpression] =
    useState(false);
  const [generatedCronJobExpression, setGeneratedCronJobExpression] =
    useState(null);
  const [description, setDescription] = useState('');

  const buildCronTabGuruLink = expression => {
    // Example
    // expression: 0 0,12 1 */2 *
    // link: https://crontab.guru/#0_0,12_1_*/2_*
    const cronTabGuruLink =
      'https://crontab.guru/#' + expression.replace(/ /g, '_');
    return cronTabGuruLink;
  };

  const handleGenerateCronJobExpression = async e => {
    e.preventDefault();
    if (!description.trim()) {
      toast.error('Enter description');
      return;
    }
    setGeneratingCronJobExpression(true);
    const r = await generateCronJobExpression(description);
    if (r.success) {
      setExpressionValue(r.response.data.expression);
      setGeneratedCronJobExpression(r.response.data.expression);
    } else {
      toast.error('Failed to generate expression');
    }
    setGeneratingCronJobExpression(false);
  };

  return (
    <Form noValidate>
      <Row className="mb-3 g-3">
        <Form.Group as={Col}>
          <Form.Label>Describe your schedule</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={e => setDescription(e.target.value)}
            value={description}
          />
          <Form.Text className="text-muted">
            Example: Every day at 9:00 PM
          </Form.Text>
        </Form.Group>
        <Form.Group className="d-flex justify-content-end">
          <Button
            type="submit"
            variant="outline-primary"
            disabled={generatingCronJobExpression}
            onClick={handleGenerateCronJobExpression}
          >
            {generatingCronJobExpression ? 'Generating...' : 'Generate'}
          </Button>
        </Form.Group>
        {generatedCronJobExpression && (
          <Alert variant="success" className="mb-0 rounded-0">
            <p className="mb-0 small">
              {generatedCronJobExpression} was generated and filled in. Please
              keep in mind that AI can make mistakes so always{' '}
              <Alert.Link
                href={buildCronTabGuruLink(generatedCronJobExpression)}
                target="_blank"
              >
                verify the generated expression.
              </Alert.Link>
            </p>
          </Alert>
        )}
      </Row>
    </Form>
  );
};

export default GenerateCronJobExpression;
