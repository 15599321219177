import React, { useContext, useEffect, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { EditorState } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import { indentUnit } from '@codemirror/language';
import { python } from '@codemirror/lang-python';
import AppContext from 'contexts/AppContext';
import {
  abcdef,
  abyss,
  androidstudio,
  andromeda,
  atomone,
  aura,
  basicDark,
  basicLight,
  bbedit,
  bespin,
  copilot,
  darcula,
  dracula,
  duotoneDark,
  duotoneLight,
  eclipse,
  gruvboxDark,
  gruvboxLight,
  materialDark,
  materialLight,
  monokai,
  monokaiDimmed,
  noctisLilac,
  nord,
  okaidia,
  quietlight,
  red,
  solarizedDark,
  solarizedLight,
  sublime,
  tokyoNight,
  tokyoNightStorm,
  githubDark,
  githubLight,
  kimbie,
  tokyoNightDay,
  tomorrowNightBlue,
  vscodeDark,
  whiteDark,
  whiteLight,
  xcodeDark,
  xcodeLight
} from '@uiw/codemirror-themes-all';

function getLanguageExtensions(language) {
  switch (language) {
    case 'python':
      return [python(), indentUnit.of(' '.repeat(4))];
    case 'plaintext':
      return [];
    default:
      return [];
  }
}

function CodeEditor({
  language,
  value,
  height,
  readOnly = false,
  onChange,
  theme: externalTheme
}) {
  const {
    config: { isDark, codeEditorThemeDark, codeEditorThemeLight }
  } = useContext(AppContext);

  const [internalTheme, setInternalTheme] = useState(
    isDark ? codeEditorThemeDark : codeEditorThemeLight
  );

  useEffect(() => {
    if (externalTheme) {
      setInternalTheme(externalTheme);
    } else {
      setInternalTheme(
        isDark
          ? getThemeObjectFromName(codeEditorThemeDark)
          : getThemeObjectFromName(codeEditorThemeLight)
      );
    }
  }, [externalTheme, isDark, codeEditorThemeDark, codeEditorThemeLight]);

  const extensions = [
    ...getLanguageExtensions(language),
    EditorView.editable.of(!readOnly),
    EditorState.readOnly.of(readOnly)
  ];

  return (
    <CodeMirror
      value={value}
      height={height}
      extensions={extensions}
      onChange={onChange}
      theme={internalTheme}
    />
  );
}

export function getThemeObjectFromName(name) {
  for (const theme of codeEdtiorThemes) {
    if (theme.name === name) {
      return theme.themeObject;
    }
  }
}

export function getThemeDisplayNameFromName(name) {
  for (const theme of codeEdtiorThemes) {
    if (theme.name === name) {
      return theme.displayName;
    }
  }
}

export const codeEdtiorThemes = [
  {
    themeObject: abcdef,
    name: 'abcdef',
    displayName: 'Abcdef'
  },
  {
    themeObject: abyss,
    name: 'abyss',
    displayName: 'Abyss'
  },
  {
    themeObject: androidstudio,
    name: 'androidstudio',
    displayName: 'Andromeda Studio'
  },
  {
    themeObject: andromeda,
    name: 'andromeda',
    displayName: 'Andromeda'
  },
  {
    themeObject: atomone,
    name: 'atomone',
    displayName: 'Atomone'
  },
  {
    themeObject: aura,
    name: 'aura',
    displayName: 'Aura'
  },
  {
    themeObject: basicDark,
    name: 'basic_dark',
    displayName: 'Basic Dark'
  },
  {
    themeObject: bespin,
    name: 'bespin',
    displayName: 'Bespin'
  },
  {
    themeObject: copilot,
    name: 'copilot',
    displayName: 'Copilot'
  },
  {
    themeObject: darcula,
    name: 'darcula',
    displayName: 'Darcula'
  },
  {
    themeObject: dracula,
    name: 'dracula',
    displayName: 'Dracula'
  },
  {
    themeObject: duotoneDark,
    name: 'duotone_dark',
    displayName: 'Duotone Dark'
  },

  {
    themeObject: githubDark,
    name: 'github_dark',
    displayName: 'Github Dark'
  },
  {
    themeObject: gruvboxDark,
    name: 'gruvbox_dark',
    displayName: 'Gruvbox Dark'
  },
  {
    themeObject: kimbie,
    name: 'kimbie_dark',
    displayName: 'Kimbie Dark'
  },
  {
    themeObject: materialDark,
    name: 'material_dark',
    displayName: 'Material Dark'
  },
  {
    themeObject: monokai,
    name: 'monokai',
    displayName: 'Monokai'
  },
  {
    themeObject: monokaiDimmed,
    name: 'monokai_dimmed',
    displayName: 'Monokai Dimmed'
  },
  {
    themeObject: nord,
    name: 'nord',
    displayName: 'Nord'
  },
  {
    themeObject: okaidia,
    name: 'okaidia',
    displayName: 'Okaidia'
  },
  {
    themeObject: red,
    name: 'red',
    displayName: 'Red'
  },
  {
    themeObject: solarizedDark,
    name: 'solarized_dark',
    displayName: 'Solarized Dark'
  },
  {
    themeObject: sublime,
    name: 'sublime',
    displayName: 'Sublime'
  },
  {
    themeObject: tokyoNight,
    name: 'tokyo_night',
    displayName: 'Tokyo Night'
  },
  {
    themeObject: tokyoNightStorm,
    name: 'tokyo_night_storm',
    displayName: 'Tokyo Night Storm'
  },
  {
    themeObject: tomorrowNightBlue,
    name: 'tomorrow_night_blue',
    displayName: 'Tomorrow Night Blue'
  },
  {
    themeObject: vscodeDark,
    name: 'vscode_dark',
    displayName: 'Vscode Dark'
  },
  {
    themeObject: whiteDark,
    name: 'white_dark',
    displayName: 'White Dark'
  },
  {
    themeObject: xcodeDark,
    name: 'xcode_dark',
    displayName: 'xcode Dark'
  },
  {
    themeObject: basicLight,
    name: 'basic_light',
    displayName: 'Basic Light'
  },
  {
    themeObject: bbedit,
    name: 'bbedit',
    displayName: 'Bbedit'
  },
  {
    themeObject: duotoneLight,
    name: 'duotone_light',
    displayName: 'Duotone Light'
  },
  {
    themeObject: eclipse,
    name: 'eclipse',
    displayName: 'Eclipse'
  },
  {
    themeObject: githubLight,
    name: 'github_light',
    displayName: 'Github Light'
  },
  {
    themeObject: gruvboxLight,
    name: 'gruvbox_light',
    displayName: 'Gruvbox Light'
  },
  {
    themeObject: materialLight,
    name: 'material_light',
    displayName: 'Material Light'
  },
  {
    themeObject: noctisLilac,
    name: 'noctis_lilac',
    displayName: 'Noctis Light'
  },
  {
    themeObject: quietlight,
    name: 'quietlight',
    displayName: 'Quietlight'
  },
  {
    themeObject: solarizedLight,
    name: 'solarized_light',
    displayName: 'Solarized Light'
  },
  {
    themeObject: tokyoNightDay,
    name: 'tokyo_night_day',
    displayName: 'Tokyo Night Day'
  },
  {
    themeObject: whiteLight,
    name: 'white_light',
    displayName: 'White Light'
  },
  {
    themeObject: xcodeLight,
    name: 'xcode_light',
    displayName: 'code Light'
  }
];

export default CodeEditor;
