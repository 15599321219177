import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getStores } from 'helpers/requests/stores';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import getColumns from 'components/stores/store-list/get-columns';
import Empty from 'components/stores/store-list/Empty';
import Header from 'components/stores/store-list/Header';
import Table from 'components/stores/store-list/Table';
import Loading from 'components/stores/store-list/Loading';
import { toast } from 'react-toastify';
import { deleteStore } from 'helpers/requests/stores';

const Stores = () => {
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(true);

  const removeStore = id => {
    setStoreList(prevStoreList =>
      prevStoreList.filter(store => store.id !== id)
    );
  };

  const handleDeleteStore = async id => {
    if (window.confirm('Are you sure you want to delete this store?')) {
      const r = await deleteStore(id);
      if (r.success) {
        removeStore(id);
        toast.success('Store deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Store not found. Maybe it was already deleted?');
        } else if (r.response.status === 400) {
          // Store may be in use.
          toast.error(r.response.data.message);
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  let handleGetStores = async () => {
    const r = await getStores();
    if (r.success) {
      setStoreList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetStores();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && storeList.length === 0 && <Empty />}
        {!loading && storeList.length > 0 && (
          <Table columns={getColumns(handleDeleteStore)} data={storeList} />
        )}
      </Card.Body>
    </Card>
  );
};

export default Stores;
