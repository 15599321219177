import React from 'react';
import { Button } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { Link } from 'react-router-dom';

const CTA = ({ useTemplateUrl }) => {
  return (
    <div
      className="position-fixed bottom-0 end-0 p-5 w-100 w-md-auto"
      style={{ zIndex: 1 }}
    >
      <Toast onClose={() => {}} show={true} className="w-100 w-md-auto">
        <Button
          as={Link}
          size="lg"
          variant="warning"
          className="w-100 px-5"
          to={useTemplateUrl}
        >
          Use Template
        </Button>
      </Toast>
    </div>
  );
};

export default CTA;
