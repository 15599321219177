import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { createStore } from 'helpers/requests/stores';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const CreateStoreForm = () => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const isMounted = useRef(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();
  const navigate = useNavigate();

  const submitForm = async data => {
    setSubmittingForm(true);
    const r = await createStore(data.name);
    if (r.success) {
      if (isMounted.current) {
        navigate(appPaths.stores.detail.replace(':id', r.response.data.id));
      }
      toast.success('Store created successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Card className="mb-3 bg-light">
        <Card.Body>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} md={6} sm={12}>
              <Form.Label>Name*</Form.Label>
              <Form.Control
                placeholder="Name"
                name="name"
                type="text"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: 'Enter name'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      {errors.root?.nonFieldError?.message && (
        <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
      )}

      <Card>
        <Card.Body>
          <Form.Group className="d-flex justify-content-end">
            <Button type="submit" disabled={submittingForm}>
              Create
            </Button>
          </Form.Group>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default CreateStoreForm;
