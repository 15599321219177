import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UpdateBotForm from './UpdateBotForm';
import { getBot } from 'helpers/requests/bots';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Error404 from 'components/common/Error404';
import Loading from 'components/bots/update-bot/Loading';

const UpdateBot = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [bot, setBot] = useState({});
  const { id } = useParams();

  let handleGetBot = async () => {
    const r = await getBot(id);
    if (r.success) {
      setBot(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  useEffect(() => {
    handleGetBot();
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  if (loading) {
    return <Loading />;
  }
  return <UpdateBotForm bot={bot} />;
};

export default UpdateBot;
