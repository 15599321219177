import React, { useEffect, useState } from 'react';
import { getBots, deleteBot } from 'helpers/requests/bots';
import { Card } from 'react-bootstrap';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Header from 'components/bots/bot-list/Header';
import getColumns from 'components/bots/bot-list/get-columns';
import Table from 'components/bots/bot-list/Table';
import Empty from 'components/bots/bot-list/Empty';
import Loading from 'components/bots/bot-list/Loading';
import { toast } from 'react-toastify';

const Bots = () => {
  const [botList, setBotList] = useState([]);
  const [loading, setLoading] = useState(true);

  const removeBot = id => {
    setBotList(prevBotList => prevBotList.filter(bot => bot.id !== id));
  };

  const handleDeleteBot = async id => {
    if (window.confirm('Are you sure you want to delete this bot?')) {
      const r = await deleteBot(id);
      if (r.success) {
        removeBot(id);
        toast.success('Bot deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Bot not found. Maybe it was already deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  let handleGetBots = async () => {
    const r = await getBots();
    if (r.success) {
      setBotList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetBots();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && botList.length === 0 && <Empty />}
        {!loading && botList.length > 0 && (
          <Table columns={getColumns(handleDeleteBot)} data={botList} />
        )}
      </Card.Body>
    </Card>
  );
};

export default Bots;
