import React from 'react';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';
import { Button } from 'react-bootstrap';
import { fromISOInUTC } from 'helpers/time';

const getColumns = handleDeleteBot => {
  return [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id, name } = rowData.row.original;
        return (
          <>
            <Link to={appPaths.bots.detail.replace(':id', id)}>
              <strong>{name}</strong>
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'created',
      Header: 'Created',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { created } = rowData.row.original;
        return <>{fromISOInUTC(created).toFormat('LLL dd, HH:mm')}</>;
      }
    },
    {
      accessor: 'python_version',
      Header: 'Python version',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'utils',
      headerProps: { className: 'pe-7' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-end">
            <Button
              variant="falcon-danger"
              size="sm"
              onClick={() => handleDeleteBot(id)}
            >
              Delete
            </Button>
          </div>
        );
      }
    }
  ];
};
export default getColumns;
