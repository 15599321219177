import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <div className="d-flex justify-content-center p-3">
      <div className="px-2">
        <Spinner animation="grow" size="sm">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <div className="px-2">
        <Spinner animation="grow" size="sm">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <div className="px-2">
        <Spinner animation="grow" size="sm">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

export default Loading;
