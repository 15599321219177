import React, { useState, useEffect } from 'react';
import { getUser } from 'helpers/requests/users';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Loading from 'components/settings/profile/Loading';
import UpdateUserForm from 'components/settings/profile/UpdateUserForm';

const UpdateUser = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  let handleGetUser = async () => {
    const r = await getUser();
    if (r.success) {
      setUser(r.response.data);
      setLoading(false);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <UpdateUserForm user={user} />;
};

export default UpdateUser;
