import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fromISOInUTC } from 'helpers/time';

const getColumns = handleDeleteAPIKey => {
  return [
    {
      accessor: 'key',
      Header: 'API key',
      headerProps: { className: 'pe-7' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { key } = rowData.row.original;
        const visibleCharCount = 8;
        const firstFour = key.substring(0, visibleCharCount);
        const restLen = key.substring(visibleCharCount, key.length).length;
        return <>{firstFour + '*'.repeat(restLen)}</>;
      }
    },
    {
      accessor: 'created',
      Header: 'Created',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { created } = rowData.row.original;
        return <>{fromISOInUTC(created).toFormat('LLL dd, HH:mm')}</>;
      }
    },
    {
      accessor: 'expires_at',
      Header: 'Expires',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { expires_at } = rowData.row.original;
        return (
          <>
            {expires_at
              ? fromISOInUTC(expires_at).toFormat('LLL dd, HH:mm')
              : 'Never'}
          </>
        );
      }
    },
    {
      accessor: 'utils',
      headerProps: { className: 'pe-7' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id, key } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-end">
            <CopyToClipboard
              text={key}
              onCopy={() => toast.success('Copied to clipboard')}
            >
              <Button variant="falcon-primary" size="sm">
                <FontAwesomeIcon icon="copy" className="me-1" />
                Copy
              </Button>
            </CopyToClipboard>
            <Button
              variant="falcon-danger"
              size="sm"
              className="ms-2"
              onClick={() => handleDeleteAPIKey(id)}
            >
              Delete
            </Button>
          </div>
        );
      }
    }
  ];
};
export default getColumns;
