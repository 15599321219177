import { verifyEmail } from 'helpers/requests/users';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const EmailVerification = () => {
  const { verificationToken } = useParams();
  const navigate = useNavigate();

  const handleVerifyEmail = async () => {
    const r = await verifyEmail(verificationToken);

    if (r.success) {
      toast.success('Email was successfully verified.');
    } else {
      if (r.response.status === 400) {
        toast.error(r.response.data.message);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    navigate(appPaths.settings.profile);
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []);
};

export default EmailVerification;
