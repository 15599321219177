import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import 'helpers/initFA';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

if (process.env.REACT_APP_USE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing(),
      new CaptureConsole(),
      new Sentry.Replay({
        maskAllText: false
      })
    ],
    tracesSampleRate: 0.5,
    tracePropagationTargets: [process.env.REACT_APP_API_BASE_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  // When StrictMode is enabled, the `isMounted.current` logic won't work.
  // <React.StrictMode>
  <Router basename={process.env.PUBLIC_URL}>
    <App />
  </Router>
  // </React.StrictMode>
);
