import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Button, Modal, Placeholder } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CreateCronJobForm from 'components/bots/bot-details/cron-jobs/CreateCronJobForm';
import {
  createBotExecutorJob,
  getLatestBotBuilderJob
} from 'helpers/requests/bots';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { apiWsPaths } from 'config';
import { getWsUrl } from 'helpers/ws-requests/ws';
import BuildStatus from 'components/bots/bot-details/panel/BuildStatus';

const PanelBody = ({ loading, bot, botId, setNewCronJob }) => {
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [latestBotBuilderJob, setLatestBotBuilderJob] = useState(null);
  const [botBuilderJobSocket, setBotBuilderJobSocket] = useState(null);
  const botBuilderJobSocketClosedIntentionally = useRef(false);

  let handleGetLatestBotBuilderJob = async () => {
    const r = await getLatestBotBuilderJob(botId);
    if (r.success) {
      setLatestBotBuilderJob(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  const initializeBotBuilderJobSocket = () => {
    botBuilderJobSocketClosedIntentionally.current = false;

    const ws = new WebSocket(
      getWsUrl(apiWsPaths.appBots.botBuilderJobs(botId))
    );
    ws.onmessage = function (e) {
      setLatestBotBuilderJob(JSON.parse(e.data));
    };
    ws.onclose = function () {
      if (!botBuilderJobSocketClosedIntentionally.current) {
        console.error(
          'Connection closed unexpectedly. Attempting to reconnect...'
        );
        initializeBotBuilderJobSocket();
      }
    };

    setBotBuilderJobSocket(ws);
  };

  const closeBotBuilderJobSocket = () => {
    if (botBuilderJobSocket) {
      botBuilderJobSocketClosedIntentionally.current = true;
      botBuilderJobSocket.close();
      setBotBuilderJobSocket(null);
    }
  };

  useEffect(() => {
    if (!loading) {
      initializeBotBuilderJobSocket();
      handleGetLatestBotBuilderJob();
    }
    return () => {
      closeBotBuilderJobSocket();
    };
  }, [loading]);

  const handleCreateBotExecutorJob = async () => {
    const r = await createBotExecutorJob(bot.id);
    if (r.success) {
      toast.success('Execution has been successfully requested.');
    } else {
      if (r.response.status === 400) {
        // Maybe the bot is being built.
        toast.error(r.response.data.message);
      } else if (r.response.status === 404) {
        toast.error('Bot not found. Maybe it was deleted?');
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  const handleScheduleModalClose = () => {
    setShowScheduleModal(false);
  };

  const handleScheduleModalOpen = () => {
    setShowScheduleModal(true);
  };

  if (loading) {
    return (
      <Card.Body>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
          <Placeholder xs={1} className="ms-2" />
        </Placeholder>
      </Card.Body>
    );
  }

  return (
    <>
      <Card.Body>
        <Row className="d-flex justify-content-between">
          <Col xs={12} md={6}>
            <SoftBadge bg="info" className="me-2">
              Python {bot.python_version}
            </SoftBadge>
            {latestBotBuilderJob && (
              <BuildStatus latestBotBuilderJob={latestBotBuilderJob} />
            )}
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-md-end mt-3 mt-md-0"
          >
            <Button
              variant="falcon-primary"
              size="sm"
              className="mx-1"
              onClick={handleCreateBotExecutorJob}
            >
              <FontAwesomeIcon icon="bolt" className="me-1" />
              Execute
            </Button>
            <Button
              variant="falcon-primary"
              size="sm"
              className="ms-1 me-2"
              onClick={handleScheduleModalOpen}
            >
              <FontAwesomeIcon icon="plus" className="me-1" />
              Schedule
            </Button>
            <CopyToClipboard
              text={bot.id}
              onCopy={() => toast.success('Copied to clipboard')}
            >
              <Button variant="falcon-primary" size="sm">
                <FontAwesomeIcon icon="copy" className="me-1" />
                Copy Bot ID
              </Button>
            </CopyToClipboard>
          </Col>
        </Row>
      </Card.Body>
      <Modal
        show={showScheduleModal}
        onHide={handleScheduleModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCronJobForm
            botId={bot.id}
            setNewCronJob={setNewCronJob}
            handleModalClose={handleScheduleModalClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PanelBody;
