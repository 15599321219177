import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function createBot(
  name,
  python_version,
  script,
  requirements,
  env_vars,
  store_id
) {
  const EXPECTED_STATUS_CODE = 201;

  try {
    const response = await ax.post(apiPaths.appBots.bots.create, {
      name: name,
      python_version: python_version,
      script: script,
      ...(requirements !== undefined && { requirements: requirements }),
      ...(env_vars !== undefined && { env_vars: env_vars }),
      store_id: store_id
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function updateBot(id, payload) {
  const EXPECTED_STATUS_CODE = 204;
  const { name, python_version, script, requirements, env_vars, store_id } =
    payload;

  try {
    const response = await ax.patch(apiPaths.appBots.bots.update(id), {
      ...(name !== undefined && { name: name }),
      ...(python_version !== undefined && { python_version: python_version }),
      ...(script !== undefined && { script: script }),
      ...(requirements !== undefined && { requirements: requirements }),
      ...(env_vars !== undefined && { env_vars: env_vars }),
      ...(store_id !== undefined && { store_id: store_id })
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBot(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBots.bots.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteBot(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(apiPaths.appBots.bots.delete(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBots() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBots.bots.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getCronJobs(botId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBots.botExecutorCronJobs.list, {
      params: { bot_id: botId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function createBotExecutorJob(botId) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(apiPaths.appBots.botExecutorJobs.create, {
      bot_id: botId
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function createBotExecutorCronJob(name, expression, botId) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(
      apiPaths.appBots.botExecutorCronJobs.create,
      {
        name: name,
        expression: expression,
        bot_id: botId
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteCronJob(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(
      apiPaths.appBots.botExecutorCronJobs.delete(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function suspendCronJob(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.patch(
      apiPaths.appBots.botExecutorCronJobs.suspend(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function unsuspendCronJob(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.patch(
      apiPaths.appBots.botExecutorCronJobs.unsuspend(id)
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function generateCronJobExpression(description) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appBots.botExecutorCronJobs.cronExpression,
      {
        params: { description: description }
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getExecutions(botId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBots.executions.list, {
      params: { bot_id: botId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBuild(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBots.builds.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getLatestBotBuilderJob(botId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBots.botBuilderJobs.latest, {
      params: { bot_id: botId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
