import React from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSimplePagination from 'components/common/advance-table/AdvanceTableSimplePagination';

const Table = ({ columns, data }) => {
  return (
    <AdvanceTableWrapper columns={columns} data={data} pagination={true}>
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          size: 'sm',
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden'
        }}
      />
      <div className="p-3">
        <AdvanceTableSimplePagination table />
      </div>
    </AdvanceTableWrapper>
  );
};

export default Table;
