import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';
import is from 'is_js';
import MainLayout from 'layouts/MainLayout';

import ErrorLayout from 'layouts/ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/common/Error404';

import Login from 'components/authentication/login/Login';
import Registration from 'components/authentication/registration/Registration';
import EmailVerification from 'components/authentication/email-confirmation/EmailVerification';

import Billing from 'components/settings/billing/Billing';
import APIKeys from 'components/settings/api-keys/APIKeys';
import Profile from 'components/settings/profile/Profile';
import UpdateUser from 'components/settings/profile/UpdateUser';
import UpdateUserPreferences from 'components/settings/profile/UpdateUserPreferences';

import BotTemplate from 'components/bot-templates/bot-template-details/BotTemplate';
import BotTemplates from 'components/bot-templates/bot-template-list/BotTemplates';
import CreateBotTemplate from 'components/bot-templates/create-bot-template/CreateBotTemplate';
import UpdateBotTemplate from 'components/bot-templates/update-bot-template/UpdateBotTemplate';

import Index from 'components/index/Index';

import Bot from 'components/bots/bot-details/Bot';
import Bots from 'components/bots/bot-list/Bots';
import CreateBot from 'components/bots/create-bot/CreateBot';
import UpdateBot from 'components/bots/update-bot/UpdateBot';

import Store from 'components/stores/store-details/Store';
import Stores from 'components/stores/store-list/Stores';
import CreateStore from 'components/stores/create-store/CreateStore';

import {
  PrivateRoute,
  PublicRoute,
  AuthRoute
} from 'components/common/CustomRoutes';
import { appPaths } from 'config';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  // Must match frontend routes defined in core/config/settings.py on the backend
  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path={appPaths.errors.notFound} element={<Error404 />} />
        </Route>

        <Route element={<AuthRoute element={<AuthLayout />} />}>
          <Route path={appPaths.auth.login} element={<Login />} />
          <Route path={appPaths.auth.register} element={<Registration />} />
          {/* <Route
            path={appPaths.auth.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route
            path={appPaths.auth.resetPassword}
            element={<PasswordReset />}
          /> */}
        </Route>

        <Route element={<PrivateRoute element={<MainLayout />} />}>
          <Route path={appPaths.index} element={<Index />} />
          <Route path={appPaths.bots.list} element={<Bots />} />
          <Route path={appPaths.bots.create} element={<CreateBot />} />
          <Route path={appPaths.bots.update} element={<UpdateBot />} />
          <Route path={appPaths.bots.detail} element={<Bot />} />
          <Route
            path={appPaths.botTemplates.create}
            element={<CreateBotTemplate />}
          />
          <Route
            path={appPaths.botTemplates.update}
            element={<UpdateBotTemplate />}
          />
          <Route path={appPaths.stores.list} element={<Stores />} />
          <Route path={appPaths.stores.create} element={<CreateStore />} />
          <Route path={appPaths.stores.detail} element={<Store />} />
          <Route path={appPaths.settings.billing} element={<Billing />} />
          <Route path={appPaths.settings.apiKeys} element={<APIKeys />} />
          <Route path={appPaths.settings.profile} element={<Profile />} />
          <Route path={appPaths.settings.updateUser} element={<UpdateUser />} />
          <Route
            path={appPaths.settings.updatePreferences}
            element={<UpdateUserPreferences />}
          />
          <Route
            path={appPaths.auth.verifyEmail}
            element={<EmailVerification />}
          />
        </Route>

        <Route element={<PublicRoute element={<MainLayout />} />}>
          <Route
            path={appPaths.botTemplates.detail}
            element={<BotTemplate />}
          />
          <Route path={appPaths.botTemplates.list} element={<BotTemplates />} />
        </Route>

        <Route
          path="*"
          element={<Navigate to={appPaths.errors.notFound} replace />}
        />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
