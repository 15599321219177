import React from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';

const BotTemplateCard = ({ botTemplate, useTemplateUrl }) => {
  const MAX_DESCRIPTION_LENGTH = 150;

  const shortenDescription = description => {
    // Replace newlines with spaces and collapse multiple spaces into one
    let ret = description.replace(/\s+/g, ' ');
    // Truncate if the length is greater than 100 characters
    if (ret.length > MAX_DESCRIPTION_LENGTH) {
      return ret.substring(0, MAX_DESCRIPTION_LENGTH - 3) + '...';
    }
    return ret;
  };

  const viewTemplateUrl = appPaths.botTemplates.detail.replace(
    ':id',
    botTemplate.id
  );

  return (
    <Col md={6} xl={4} xxl={3} className="mb-3">
      <Card className="h-100">
        <Card.Header>
          <Row>
            <Col xs={12}>
              <Card.Title>
                <Button
                  variant="link"
                  className="p-0"
                  as={Link}
                  to={viewTemplateUrl}
                >
                  {botTemplate.name}
                </Button>
              </Card.Title>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="py-1">
          <Card.Text className="text-muted">
            <small>{shortenDescription(botTemplate.description)}</small>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
          <Button
            as={Link}
            to={viewTemplateUrl}
            variant="link"
            size="sm"
            className="ps-0"
          >
            View
          </Button>
          <Button
            as={Link}
            to={useTemplateUrl}
            variant="outline-primary"
            size="sm"
          >
            Use Template
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default BotTemplateCard;
