import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from 'Layout';
import AuthContextProvider from 'providers/AuthContextProvider';
import AppContextProvider from 'providers/AppContextProvider';

const App = () => {
  return (
    <>
      <AuthContextProvider>
        <AppContextProvider>
          <Layout />
        </AppContextProvider>
      </AuthContextProvider>
    </>
  );
};

export default App;
