import React from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  deleteCronJob,
  suspendCronJob,
  unsuspendCronJob
} from 'helpers/requests/bots';
import CronJob from 'components/bots/bot-details/cron-jobs/CronJob';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const CronJobs = ({ cronJobs, setCronJobs }) => {
  const handleDeleteCronJob = async id => {
    if (window.confirm('Are you sure you want to delete this cron job?')) {
      const r = await deleteCronJob(id);
      if (r.success) {
        toast.success('Cron job deleted');
        setCronJobs(prevCronJobs =>
          prevCronJobs.filter(cronJob => cronJob.id !== id)
        );
      } else {
        if (r.response.status === 404) {
          toast.error('Cron job not found. Maybe it was deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  const handleSuspendCronJob = async id => {
    const r = await suspendCronJob(id);
    if (r.success) {
      toast.success('Cron job paused');
      setCronJobs(prevCronJobs =>
        prevCronJobs.map(cronJob =>
          cronJob.id === id ? { ...cronJob, status: 'suspended' } : cronJob
        )
      );
    } else {
      if (r.response.status === 404) {
        toast.error('Cron job not found. Maybe it was deleted?');
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  const handleUnsuspendCronJob = async id => {
    const r = await unsuspendCronJob(id);
    if (r.success) {
      toast.success('Cron job resumed');
      setCronJobs(prevCronJobs =>
        prevCronJobs.map(cronJob =>
          cronJob.id === id ? { ...cronJob, status: 'running' } : cronJob
        )
      );
    } else {
      if (r.response.status === 404) {
        toast.error('Cron job not found. Maybe it was deleted?');
      } else if (r.response.status === 400) {
        // Maybe billing account is suspended for non-payment
        toast.error(r.response.data.message);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  return (
    cronJobs.length > 0 && (
      <Row>
        {cronJobs.map(cronJob => (
          <CronJob
            key={cronJob.id}
            cronJob={cronJob}
            handleSuspendCronJob={handleSuspendCronJob}
            handleUnsuspendCronJob={handleUnsuspendCronJob}
            handleDeleteCronJob={handleDeleteCronJob}
          />
        ))}
      </Row>
    )
  );
};

export default CronJobs;
