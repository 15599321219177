import React from 'react';
import { Card, Placeholder, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PanelHeaderUnauthenticated = ({
  loading,
  botTemplate,
  useTemplateUrl
}) => {
  if (loading) {
    return (
      <Card.Header>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Card.Header>
    );
  }

  return (
    <Card.Header>
      <Row className="d-flex">
        <Col xs={12} md={8} className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <h5 className="fs-0 mb-0 d-flex align-items-center">
              {botTemplate.name}
            </h5>
          </div>
        </Col>
        <Col
          xs={12}
          md={4}
          className="d-flex justify-content-end align-items-center mt-3 mt-md-0"
        >
          <Button
            variant="primary"
            size="sm"
            as={Link}
            to={useTemplateUrl}
            className="w-100 w-md-auto"
          >
            Use Template
          </Button>
        </Col>
      </Row>
    </Card.Header>
  );
};

export default PanelHeaderUnauthenticated;
