import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Placeholder, Card } from 'react-bootstrap';
import { getCheckoutUrl } from 'helpers/requests/billing';
import { toast } from 'react-toastify';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { getBalancePurchaseOffers } from 'helpers/requests/billing';

const BalancePurchaseOffers = () => {
  const [gettingCheckoutUrl, setGettingCheckoutUrl] = useState(false);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetBalancePurchaseOffers = async () => {
    const r = await getBalancePurchaseOffers();
    if (r.success) {
      setOffers(r.response.data);
    }
    setLoading(false);
  };

  const handleGetCheckoutUrl = async offerId => {
    setGettingCheckoutUrl(true);
    toast.info('Please wait.');

    const r = await getCheckoutUrl(offerId);
    if (r.success) {
      window.location.href = r.response.data.checkout_url;
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setGettingCheckoutUrl(false);
  };

  const generateButtonText = offer => {
    const hourSuffix = offer.hours > 1 ? 's' : '';
    const dollars = (offer.price / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return `Buy ${offer.hours} hour${hourSuffix} for ${dollars}`;
  };

  useEffect(() => {
    handleGetBalancePurchaseOffers();
  }, []);

  if (loading) {
    return (
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col sm={6} md={3} className="my-2">
              <Placeholder.Button
                variant="outline-primary"
                className="w-100 p-2"
              />
            </Col>
            <Col sm={6} md={3} className="my-2">
              <Placeholder.Button
                variant="outline-primary"
                className="w-100 p-2"
              />
            </Col>
            <Col sm={6} md={3} className="my-2">
              <Placeholder.Button
                variant="outline-primary"
                className="w-100 p-2"
              />
            </Col>
            <Col sm={6} md={3} className="my-2">
              <Placeholder.Button
                variant="outline-primary"
                className="w-100 p-2"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
    );
  }

  return (
    <>
      {offers.length > 0 && (
        <Card className="mb-3">
          <Card.Header>
            <Row>
              {offers.map(offer => (
                <Col sm={6} md={3} className="my-2" key={offer.id}>
                  <Button
                    variant="outline-primary"
                    className="w-100 p-2"
                    onClick={() => handleGetCheckoutUrl(offer.id)}
                    disabled={gettingCheckoutUrl}
                  >
                    {generateButtonText(offer)}
                  </Button>
                </Col>
              ))}
            </Row>
          </Card.Header>
        </Card>
      )}
    </>
  );
};

export default BalancePurchaseOffers;
