import React, { useState, useEffect } from 'react';
import CreateBotForm from 'components/bots/create-bot/CreateBotForm';
import { getBotTemplate } from 'helpers/requests/bot-templates';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateBot = () => {
  const [botTemplate, setBotTemplate] = useState(null);
  const location = useLocation();

  let handleGetBotTemplate = async botTemplateId => {
    toast.warning('Loading the template. Please wait.');
    const r = await getBotTemplate(botTemplateId);
    if (r.success) {
      setBotTemplate(r.response.data);
      toast.success('Template was loaded successfully.');
    } else {
      toast.error('Failed to load the template.');
    }
  };

  let getTemplateIdFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('template');
  };

  useEffect(() => {
    const templateId = getTemplateIdFromUrl();
    if (templateId !== null) {
      handleGetBotTemplate(templateId);
    }
  }, []);

  return <CreateBotForm botTemplate={botTemplate} />;
};

export default CreateBot;
