import { appPaths } from 'config';

export const bots = {
  label: 'Bots',
  labelDisable: false,
  children: [
    {
      name: 'Create a Bot',
      icon: 'plus',
      to: appPaths.bots.create,
      active: true
    },
    {
      name: 'My Bots',
      icon: 'robot',
      to: appPaths.bots.list,
      active: true
    },
    {
      name: 'Bot Templates',
      icon: 'clone',
      to: appPaths.botTemplates.list,
      active: true
    }
  ]
};

export const stores = {
  label: 'Stores',
  labelDisable: false,
  children: [
    {
      name: 'Create a Store',
      icon: 'plus',
      to: appPaths.stores.create,
      active: true
    },
    {
      name: 'My Stores',
      icon: 'database',
      to: appPaths.stores.list,
      active: true
    }
  ]
};

export const learn = {
  label: 'Learn',
  labelDisable: false,
  children: [
    {
      name: 'Documentation',
      active: true,
      icon: 'book-open',
      children: [
        {
          name: 'Getting Started',
          to: 'https://botfleet.ai/docs/getting-started',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'Data Storage',
          to: 'https://botfleet.ai/docs/getting-started/data-storage',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'REST API',
          to: 'https://botfleet.ai/docs/api/v1/introduction',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'Python SDK',
          to: 'https://botfleet.ai/docs/sdk/introduction',
          exact: true,
          active: true,
          newTab: true
        }
      ]
    }
  ]
};

export default [bots, stores, learn];
