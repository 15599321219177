import React, { useState, useEffect } from 'react';
import { getBillingAccount } from 'helpers/requests/billing';
import BalancePurchaseOffers from 'components/settings/billing/balance-purchase-offers/BalancePurchaseOffers';
import BalancePurchases from 'components/settings/billing/balance-purchases/BalancePurchases';
import Balance from 'components/settings/billing/balance/Balance';

const Billing = () => {
  const [loadingBillingAccount, setLoadingBillingAccount] = useState(true);
  const [billingAccount, setBillingAccount] = useState({});

  const handleGetBillingAccount = async () => {
    const r = await getBillingAccount();
    if (r.success) {
      setBillingAccount(r.response.data);
      setLoadingBillingAccount(false);
    }
  };

  useEffect(() => {
    handleGetBillingAccount();
  }, []);

  return (
    <>
      <Balance
        loadingBillingAccount={loadingBillingAccount}
        billingAccount={billingAccount}
      />
      <BalancePurchaseOffers />
      <BalancePurchases />
    </>
  );
};

export default Billing;
