import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import TerminalModal from 'components/common/TerminalModal';
import { getBuild } from 'helpers/requests/bots';
import SoftBadge from 'components/common/SoftBadge';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const BuildStatus = ({ latestBotBuilderJob: job }) => {
  const [loadingBuildLogs, setLoadingBuildLogs] = useState(false);
  const [showBuildLogsModal, setShowBuildLogsModal] = useState(false);
  const [buildLogsModalContent, setBuildLogsModalContent] = useState('');

  const handleBuildLogsModalClose = () => {
    setShowBuildLogsModal(false);
  };

  const handleBuildLogsModalOpen = buildId => {
    handleGetBuildLogs(buildId);
    setShowBuildLogsModal(true);
  };

  let handleGetBuildLogs = async buildId => {
    setLoadingBuildLogs(true);
    const r = await getBuild(buildId);
    if (r.success) {
      const logs = r.response.data.logs;
      const content =
        logs.length > 0
          ? logs.map(({ log, time }) => `${time}: ${log}`).join('\n')
          : 'No logs to show.';
      setBuildLogsModalContent(content);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoadingBuildLogs(false);
  };

  const getBadgeColor = status => {
    switch (status) {
      case 'success':
        return 'info';
      case 'failure':
        return 'danger';
      case 'in_progress':
        return 'warning';
      case null:
        return 'warning';
      default:
        return 'danger';
    }
  };

  const getMessage = status => {
    switch (status) {
      case 'success':
        return 'Build succeeded';
      case 'failure':
        return 'Failed to build';
      case 'in_progress':
        return 'Build in progress';
      case null:
        return 'Build queued';
      default:
        return 'Unknown';
    }
  };

  const status = job.build ? job.build.status : null;

  return (
    <>
      <SoftBadge bg={getBadgeColor(status)}>{getMessage(status)}</SoftBadge>
      {status !== 'success' && status !== 'failure' && (
        <Spinner
          animation="grow"
          variant="warning"
          style={{ width: '0.75rem', height: '0.75rem' }}
          className="ms-2"
        />
      )}
      {status === 'failure' && (
        <Button
          variant="link"
          size="sm"
          onClick={() => handleBuildLogsModalOpen(job.build.id)}
        >
          <small>View build logs</small>
        </Button>
      )}
      <TerminalModal
        content={buildLogsModalContent}
        show={showBuildLogsModal}
        onHide={handleBuildLogsModalClose}
        loading={loadingBuildLogs}
      />
    </>
  );
};

export default BuildStatus;
