import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card } from 'react-bootstrap';
import { getAPIKeys, deleteAPIKey, createAPIKey } from 'helpers/requests/users';
import Empty from 'components/settings/api-keys/Empty';
import Table from 'components/settings/api-keys/Table';
import Loading from 'components/settings/api-keys/Loading';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import getColumns from './get-columns';
import Header from 'components/settings/api-keys/Header';

const APIKeys = () => {
  const [loading, setLoading] = useState(true);
  const [APIKeyList, setAPIKeyList] = useState({});

  const addAPIKey = newAPIKey => {
    setAPIKeyList(prevAPIKeyList => [newAPIKey, ...prevAPIKeyList]);
  };

  const removeAPIKey = id => {
    setAPIKeyList(prevAPIKeyList =>
      prevAPIKeyList.filter(APIKey => APIKey.id !== id)
    );
  };

  const handleCreateAPIKey = async () => {
    const r = await createAPIKey();
    if (r.success) {
      addAPIKey(r.response.data);
      toast.success('API key created.');
    } else {
      if (r.response.status === 400) {
        toast.error(r.response.data.message);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  const handleDeleteAPIKey = async id => {
    if (window.confirm('Are you sure you want to delete this API key?')) {
      const r = await deleteAPIKey(id);
      if (r.success) {
        removeAPIKey(id);
        toast.success('API key deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('API key not found. Maybe it was already deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  const handleGetAPIKeys = async () => {
    const r = await getAPIKeys();
    if (r.success) {
      setAPIKeyList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetAPIKeys();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header loading={loading} handleCreateAPIKey={handleCreateAPIKey} />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && APIKeyList.length === 0 && (
          <Empty handleCreateAPIKey={handleCreateAPIKey} />
        )}
        {!loading && APIKeyList.length > 0 && (
          <Table columns={getColumns(handleDeleteAPIKey)} data={APIKeyList} />
        )}
      </Card.Body>
    </Card>
  );
};

export default APIKeys;
