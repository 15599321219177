/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, Form } from 'react-bootstrap';

export function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <FormControl
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search...`}
      size="sm"
      type="search"
      className="shadow-none"
    />
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Form.Select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      size="sm"
      className="shadow-none"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
}
