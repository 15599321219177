import React, { useRef, useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import HourlyExecutionsChart from 'components/stats/executions/HourlyExecutionsChart';
import { getHourlyExecutions } from 'helpers/requests/stats';

const HourlyExecutions = () => {
  const chartRef = useRef(null);
  const [successes, setSuccesses] = useState([]);
  const [failures, setFailures] = useState([]);
  const [both, setBoth] = useState([]);
  const [datetimes, setDatetimes] = useState([]);
  const [executionStatus, setExecutionStatus] = useState('success');

  const getDataByExecutionStatus = () => {
    switch (executionStatus) {
      case 'success':
        return successes;
      case 'failure':
        return failures;
      case 'both':
        return both;
      default:
        return [];
    }
  };

  const handleGetHourlyExecutions = async () => {
    const r = await getHourlyExecutions(24);
    if (r.success) {
      // Even though the data from the api may be sorted, we sort it again to be 100% sure.
      // new Date() is used only for sorting. Otherwise, luxon is used to maintain UTC.
      const data = r.response.data.sort(
        (a, b) => new Date(a.dt) - new Date(b.dt)
      );
      setSuccesses(data.map(item => item.successes));
      setFailures(data.map(item => item.failures));
      setBoth(data.map(item => item.successes + item.failures));
      setDatetimes(data.map(item => item.dt));
    }
  };

  useEffect(() => {
    handleGetHourlyExecutions();
  }, []);

  return (
    <Card className="rounded-3 overflow-hidden h-100 shadow-none">
      <Card.Body
        className="bg-line-chart-gradient"
        as={Flex}
        justifyContent="between"
        direction="column"
      >
        <Row className="align-items-center g-0">
          <Col className="light">
            <h6 className="mb-0 text-white">Last 24 hours</h6>
          </Col>
          <Col xs="auto" className="d-none d-sm-block">
            <Form.Select
              size="sm"
              className="mb-3"
              value={executionStatus}
              onChange={e => setExecutionStatus(e.target.value)}
            >
              <option value="success">Successes</option>
              <option value="failure">Failures</option>
              <option value="both">Both</option>
            </Form.Select>
          </Col>
        </Row>
        <HourlyExecutionsChart
          ref={chartRef}
          data={getDataByExecutionStatus()}
          datetimes={datetimes}
        />
      </Card.Body>
    </Card>
  );
};

export default HourlyExecutions;
