import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { forwardRef } from 'react';
import { fromISOInUTC } from 'helpers/time';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent,
  CanvasRenderer
]);

const tooltipFormatter = params => {
  return params
    .map(
      ({ value, borderColor }, index) =>
        `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
              <span class='text-600'>${
                index === 0 ? 'Successes' : 'Failures'
              }: ${value}</span>`
    )
    .join('<br/>');
};

const getOptions = (successes, failures, datetimes) => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: ['success', 'failure'],
    show: false
  },
  xAxis: {
    type: 'category',
    data: datetimes,
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => {
        const dt = fromISOInUTC(value);
        return `${dt.monthShort} ${dt.day}`;
      }
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      name: 'success',
      type: 'line',
      data: successes,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    },
    {
      name: 'failure',
      type: 'line',
      data: failures,
      lineStyle: { color: rgbaColor(getColor('danger'), 0.3) },
      itemStyle: {
        borderColor: rgbaColor(getColor('danger'), 0.6),
        borderWidth: 2
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      }
    }
  ],
  grid: { right: '18px', left: '40px', bottom: '15%', top: '5%' }
});

const DailyExecutionsChart = forwardRef(
  ({ successes, failures, datetimes }, ref) => {
    return (
      <ReactEChartsCore
        ref={ref}
        echarts={echarts}
        option={getOptions(successes, failures, datetimes)}
      />
    );
  }
);

export default DailyExecutionsChart;
