import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { getBots } from 'helpers/requests/bots';
import { getStores } from 'helpers/requests/stores';
import BotsInfo from 'components/stats/generic/BotsInfo';
import StoresInfo from 'components/stats/generic/StoresInfo';

const Generic = () => {
  const [botCount, setBotCount] = useState(0);
  const [storeCount, setStoreCount] = useState(0);

  const handleGetBots = async () => {
    const r = await getBots();
    if (r.success) {
      setBotCount(r.response.data.length);
    }
  };

  const handleGetStores = async () => {
    const r = await getStores();
    if (r.success) {
      setStoreCount(r.response.data.length);
    }
  };

  useEffect(() => {
    handleGetBots();
    handleGetStores();
  }, []);

  return (
    <Row className="g-3 mb-0">
      <BotsInfo count={botCount} />
      <StoresInfo count={storeCount} />
    </Row>
  );
};

export default Generic;
