import React from 'react';
import { fromISOInUTC } from 'helpers/time';

const getColumns = () => {
  return [
    {
      accessor: 'created',
      Header: 'Date',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { created } = rowData.row.original;
        return <>{fromISOInUTC(created).toFormat('LLL dd, HH:mm')}</>;
      }
    },
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'hours',
      Header: 'Hours',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'price',
      Header: 'Price',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { price } = rowData.row.original;
        return (
          <>
            {(price / 100).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })}
          </>
        );
      }
    }
  ];
};
export default getColumns;
