import React, { useState, useEffect } from 'react';
import { Card, Alert } from 'react-bootstrap';
import { getBalancePurchases } from 'helpers/requests/billing';
import Loading from 'components/settings/billing/balance-purchases/Loading';
import Table from 'components/settings/billing/balance-purchases/Table';
import Header from 'components/settings/billing/balance-purchases/Header';
import getColumns from 'components/settings/billing/balance-purchases/get-columns';

const BalancePurchases = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);

  const handleGetBalancePurchases = async () => {
    const r = await getBalancePurchases();
    if (r.success) {
      setPurchases(r.response.data);
    } else {
      setFailedToLoad(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetBalancePurchases();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {failedToLoad && (
          <Alert key={'danger'} variant={'danger'} className="mx-3">
            Failed to load transactions.
          </Alert>
        )}
        {!loading && !failedToLoad && purchases.length == 0 && (
          <Alert key={'secondary'} variant={'secondary'} className="mx-3">
            You have no transactions.
          </Alert>
        )}
        {!loading && !failedToLoad && purchases.length > 0 && (
          <Table columns={getColumns()} data={purchases} />
        )}
      </Card.Body>
    </Card>
  );
};

export default BalancePurchases;
