import { errorCodes, nonFieldErrorsKey, genericErrorMessage } from 'config';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

const setValidationErrors = (fields, setError) => {
  Object.entries(fields).forEach(([field, errors]) => {
    if (field === nonFieldErrorsKey) {
      setError('root.nonFieldError', {
        message: errors[0]
      });
    } else {
      setError(field, { message: errors[0] });
    }
  });
};

export const setFormErrors = (errorData, setError) => {
  if (errorData.code === errorCodes.validationError) {
    setValidationErrors(errorData.extra, setError);
  } else {
    setError('root.nonFieldError', {
      message: errorData.message
    });
  }
};

export const handleUnexpectedStatusCode = (
  expectedStatusCode,
  receivedStatusCode
) => {
  toast.error(genericErrorMessage);
  Sentry.captureException(new Error('Unexpected status code.'), {
    extra: {
      expectedStatusCode: expectedStatusCode,
      receivedStatusCode: receivedStatusCode
    }
  });
};
