import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import editing from 'assets/img/illustrations/starter.png';

const Empty = ({ handleCreateAPIKey }) => {
  return (
    <Row className="align-items-center justify-content-between mx-0">
      <Col lg={6}>
        <img src={editing} className="img-fluid" alt="" />
      </Col>
      <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
        <h3>You have no API keys</h3>
        <p className="lead">Create your first API key</p>
        <Button variant="falcon-primary" onClick={() => handleCreateAPIKey()}>
          Create
        </Button>
      </Col>
    </Row>
  );
};
export default Empty;
