import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'components/common/Error404';
import Panel from 'components/bot-templates/bot-template-details/panel/Panel';
import Script from 'components/bots/bot-details/Script';
import EnvVars from 'components/bots/bot-details/EnvVars';
import Requirements from 'components/bots/bot-details/Requirements';
import { getBotTemplate } from 'helpers/requests/bot-templates';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import CTA from 'components/bot-templates/bot-template-details/CTA';
import AuthContext from 'contexts/AuthContext';
import { getUseTemplateUrl } from 'components/bot-templates/helpers';

const BotTemplate = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [botTemplate, setBotTemplate] = useState({});
  const { id } = useParams();
  const { isAuthenticated } = useContext(AuthContext);

  const useTemplateUrl = getUseTemplateUrl(id, isAuthenticated);

  let handleGetBotTemplate = async () => {
    const r = await getBotTemplate(id);
    if (r.success) {
      setBotTemplate(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  useEffect(() => {
    handleGetBotTemplate();
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <>
      {!isAuthenticated && <CTA useTemplateUrl={useTemplateUrl} />}
      <Panel
        loading={loading}
        botTemplate={botTemplate}
        useTemplateUrl={useTemplateUrl}
        isAuthenticated={isAuthenticated}
      />
      {!loading && (
        <>
          <Script value={botTemplate.script} isCollapsed={false} />
          <Requirements value={botTemplate.requirements} isCollapsed={false} />
          <EnvVars value={botTemplate.env_vars} isCollapsed={false} />
        </>
      )}
    </>
  );
};

export default BotTemplate;
