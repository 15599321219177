import React from 'react';
import { Row, Col, Card, Placeholder } from 'react-bootstrap';

const PanelBody = ({ loading, botTemplate }) => {
  if (loading) {
    return (
      <>
        <hr className="my-0" />
        <Card.Body>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={12} />
            <Placeholder xs={12} />
            <Placeholder xs={12} />
          </Placeholder>
        </Card.Body>
      </>
    );
  }

  if (!botTemplate.description) return null;

  return (
    <>
      <hr className="my-0" />
      <Card.Body>
        <Row className="d-flex justify-content-between">
          <Col xs={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: botTemplate.description.replace(/\n/g, '<br>')
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

export default PanelBody;
