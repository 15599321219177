import React from 'react';
import { Col, Row } from 'react-bootstrap';
import HourlyExecutions from 'components/stats/executions/HourlyExecutions';
import DailyExecutions from 'components/stats/executions/DailyExecutions';
import Generic from 'components/stats/generic/Generic';

const Index = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col sm={12}>
          <HourlyExecutions />
        </Col>
        <Col sm={12}>
          <Generic />
        </Col>
        <Col sm={12}>
          <DailyExecutions />
        </Col>
      </Row>
    </>
  );
};

export default Index;
