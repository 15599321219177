import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

const Loading = () => {
  return (
    <Card className="bg-light">
      <Card.Body>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={2} />
        </Placeholder>
      </Card.Body>
    </Card>
  );
};

export default Loading;
