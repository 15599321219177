import React from 'react';

const Login = () => {
  return (
    <div className="text-center">
      <span>BotFleet is currently invite-only.</span>
    </div>
  );
};

export default Login;
