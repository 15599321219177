import React, { useRef, useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DailyExecutionsChart from 'components/stats/executions/DailyExecutionsChart';
import { getDailyExecutions } from 'helpers/requests/stats';

const DailyExecutions = () => {
  const [successes, setSuccesses] = useState([]);
  const [failures, setFailures] = useState([]);
  const [datetimes, setDatetimes] = useState([]);
  const chartRef = useRef(null);

  const handleLegendToggle = name => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
  };

  const handleGetDailyExecutions = async () => {
    const r = await getDailyExecutions(7);
    if (r.success) {
      // Even though the data from the api may be sorted, we sort it again to be 100% sure.
      // new Date() is used only for sorting. Otherwise, luxon is used to maintain UTC.
      const data = r.response.data.sort(
        (a, b) => new Date(a.dt) - new Date(b.dt)
      );
      setSuccesses(data.map(item => item.successes));
      setFailures(data.map(item => item.failures));
      setDatetimes(data.map(item => item.dt));
    }
  };

  useEffect(() => {
    handleGetDailyExecutions();
  }, []);

  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <h6 className="mb-0">Last week</h6>
          </Col>
          <Col xs="auto" className="d-flex">
            <Form.Check
              type="checkbox"
              id="execution-successes"
              className="mb-0 d-flex"
            >
              <Form.Check.Input
                type="checkbox"
                className="form-check-input-primary"
                onClick={() => handleLegendToggle('success')}
                defaultChecked
              />
              <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Successes
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              type="checkbox"
              id="execution-failures"
              className="mb-0 d-flex ps-0 ps-md-3"
            >
              <Form.Check.Input
                type="checkbox"
                className="ms-2 form-check-input-danger opacity-75"
                onClick={() => handleLegendToggle('failure')}
                defaultChecked
              />
              <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Failures
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="pe-xxl-0">
        <DailyExecutionsChart
          successes={successes}
          failures={failures}
          datetimes={datetimes}
          ref={chartRef}
        />
      </Card.Body>
    </Card>
  );
};

export default DailyExecutions;
