import React from 'react';
import { Card } from 'react-bootstrap';
import PanelHeaderAuthenticated from 'components/bot-templates/bot-template-details/panel/PanelHeaderAuthenticated';
import PanelHeaderUnauthenticated from 'components/bot-templates/bot-template-details/panel/PanelHeaderUnauthenticated';
import PanelBody from 'components/bot-templates/bot-template-details/panel/PanelBody';

const Panel = ({ loading, botTemplate, useTemplateUrl, isAuthenticated }) => {
  return (
    <Card className="mb-3">
      {isAuthenticated ? (
        <PanelHeaderAuthenticated
          loading={loading}
          botTemplate={botTemplate}
          useTemplateUrl={useTemplateUrl}
        />
      ) : (
        <PanelHeaderUnauthenticated
          loading={loading}
          botTemplate={botTemplate}
          useTemplateUrl={useTemplateUrl}
        />
      )}
      <PanelBody loading={loading} botTemplate={botTemplate} />
    </Card>
  );
};

export default Panel;
