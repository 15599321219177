import React from 'react';
import { Card } from 'react-bootstrap';
import PanelHeader from 'components/bots/bot-details/panel/PanelHeader';
import PanelBody from 'components/bots/bot-details/panel/PanelBody';

const Panel = ({ loading, bot, botId, setNewCronJob }) => {
  return (
    <Card className="mb-3">
      <PanelHeader loading={loading} bot={bot} />
      <hr className="my-0" />
      <PanelBody
        loading={loading}
        bot={bot}
        botId={botId}
        setNewCronJob={setNewCronJob}
      />
    </Card>
  );
};

export default Panel;
