import React, { useEffect, useRef } from 'react';
import { Card, Dropdown, Placeholder, Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { deleteBotTemplate } from 'helpers/requests/bot-templates';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { appPaths } from 'config';
import SoftBadge from 'components/common/SoftBadge';

const PanelHeaderAuthenticated = ({ loading, botTemplate, useTemplateUrl }) => {
  const navigate = useNavigate();
  const isMounted = useRef(true);

  const handleDeleteBotTemplate = async () => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      const r = await deleteBotTemplate(botTemplate.id);
      if (r.success) {
        if (isMounted.current) {
          navigate(appPaths.botTemplates.list);
        }
        toast.success('Template deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Template not found. Maybe it was deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <Card.Header>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Card.Header>
    );
  }

  return (
    <Card.Header>
      <Row className="d-flex">
        <Col
          xs={9}
          md={6}
          className="d-flex justify-content-between align-items-center"
        >
          <h5 className="fs-0 mb-0 d-flex align-items-center">
            {botTemplate.name}
          </h5>
        </Col>
        <Col
          xs={3}
          md={6}
          className="d-flex align-items-center justify-content-end"
        >
          <Button
            variant="primary"
            size="sm"
            as={Link}
            to={useTemplateUrl}
            className="d-none d-md-inline-block"
          >
            Use Template
          </Button>
          {botTemplate.owner && (
            <>
              <SoftBadge bg="info" className="mx-2">
                {botTemplate.public ? 'Public' : 'Private'}
              </SoftBadge>
              <Dropdown
                align="end"
                className="btn-reveal-trigger d-inline-block"
              >
                <Dropdown.Toggle split variant="falcon-primary" size="sm">
                  <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
                    <Dropdown.Item
                      className="text-primary"
                      as={Link}
                      to={appPaths.botTemplates.update.replace(
                        ':id',
                        botTemplate.id
                      )}
                    >
                      Edit template
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-danger"
                      as={Link}
                      onClick={handleDeleteBotTemplate}
                    >
                      Delete template
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Col>
        <Col xs={12} className="d-md-none w-100 mt-3">
          <Button
            variant="primary"
            size="sm"
            as={Link}
            to={useTemplateUrl}
            className="w-100"
          >
            Use Template
          </Button>
        </Col>
      </Row>
    </Card.Header>
  );
};

export default PanelHeaderAuthenticated;
