import React from 'react';
import CollapsibleCard from 'components/common/CollapsibleCard';
import CodeEditor from 'components/common/code-editor/CodeEditor';

const Script = ({ value, isCollapsed }) => {
  return (
    <div className="mb-3">
      <CollapsibleCard title="Script" isCollapsed={isCollapsed}>
        <CodeEditor
          language="python"
          value={value}
          height="70vh"
          readOnly={true}
        />
      </CollapsibleCard>
    </div>
  );
};

export default Script;
