import React, { useState } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';

const CronJob = ({
  cronJob,
  handleSuspendCronJob,
  handleUnsuspendCronJob,
  handleDeleteCronJob
}) => {
  const [changingStatus, setChangingStatus] = useState(false);

  const suspendCronJob = async cronJobId => {
    setChangingStatus(true);
    await handleSuspendCronJob(cronJobId);
    setChangingStatus(false);
  };

  const unsuspendCronJob = async cronJobId => {
    setChangingStatus(true);
    await handleUnsuspendCronJob(cronJobId);
    setChangingStatus(false);
  };

  const deleteCronJob = async cronJobId => {
    setChangingStatus(true);
    await handleDeleteCronJob(cronJobId);
    setChangingStatus(false);
  };

  return (
    <Col xs={12} md={6} key={cronJob.id}>
      <Card className="mb-3">
        <Card.Body>
          <Row className="mb-3">
            <Col className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Scheduled - {cronJob.name}</h6>
              <SoftBadge
                bg={cronJob.status === 'running' ? 'success' : 'warning'}
              >
                {cronJob.status === 'running' ? 'Running' : 'Paused'}
              </SoftBadge>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col>
              <Card.Text>{cronJob.expression}</Card.Text>
            </Col>
            <Col className="d-flex justify-content-end">
              {cronJob.status === 'running' && (
                <Button
                  variant="outline-primary"
                  size="sm me-2"
                  onClick={() => suspendCronJob(cronJob.id)}
                  disabled={changingStatus}
                >
                  Pause
                </Button>
              )}
              {cronJob.status === 'suspended' && (
                <Button
                  variant="outline-primary"
                  size="sm me-2"
                  onClick={() => unsuspendCronJob(cronJob.id)}
                  disabled={changingStatus}
                >
                  Resume
                </Button>
              )}
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => deleteCronJob(cronJob.id)}
                disabled={changingStatus}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CronJob;
