import React from 'react';
import CollapsibleCard from 'components/common/CollapsibleCard';
import CodeEditor from 'components/common/code-editor/CodeEditor';

const EnvVars = ({ value, isCollapsed }) => {
  return (
    <div className="mb-3">
      <CollapsibleCard title="Environment variables" isCollapsed={isCollapsed}>
        <CodeEditor
          language="plaintext"
          value={value}
          height="15vh"
          readOnly={true}
        />
      </CollapsibleCard>
    </div>
  );
};

export default EnvVars;
